import * as React from "react"
import Seo from "@src/components/seo"
import Layout from "@src/components/templates/layout"
import PageHeader from "@src/components/molecules/page-header"
import Contact from "@src/components/pages/contact"

const ContactPage = () => {
    return(
        <Layout>
            <Seo title="お問い合わせ" />
            <PageHeader title="お問い合わせ" />
            <article className="contact">
                <div className="l-contents-padding">
                    <Contact />
                </div>
            </article>
        </Layout>
    )
}

export default ContactPage