import * as React from "react";
import Tel from "@src/components/atoms/tel/index";

import "./style.css"

const initialState = {
    form: {
        name: "",
        strname: "",
        mail: "",
        text: "",
    },
    error: {
        name: "",
        strname: "",
        mail: "",
        text: "",
    },
    isSubmit: false,
    serverMessage: "",
}

const url = "https://94pk1z4pl9.execute-api.ap-northeast-1.amazonaws.com/mainhp-mail";

const Contact = () => {
    const [form, setForm] = React.useState(initialState.form);
    const [error, setError] = React.useState(initialState.error);
    const [isSubmit, setIsSubmit] = React.useState(initialState.isSubmit)
    const [serverMessage, setServerMessage] = React.useState(initialState.serverMessage);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
            return;
        } 

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(form)
            });
            if (response.status === 200) {
                setIsSubmit(true)
            }
        } catch (error) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
            setServerMessage("お問い合わせ内容の送信に失敗しました。")
            console.log(error.message)
        }
    }

    /**
     * 入力内容の検証
     * @returns {boolean} エラーがある -> true
     */
    const validateForm = () => {
        let flag = false;
        const errorMessage = {
            name: validateName(),
            strname: validateStrName(),
            mail: validateMail(),
            text: validateText(),
        }
        for (const key in errorMessage) {
            if (errorMessage[key]) flag = true;
        }
        setError(errorMessage)
        return flag;
    }

    const validateName = () => {
        let message = "";
        if (!message && !form.name) {
            message = "氏名を入力してください。";
        }
        return message;
    }

    const validateStrName = () => {
        let message = "";
        if (!message && !form.strname) {
            message = "所属を入力してください。";
        }
        return message;
    }

    const validateMail = () => {
        let message = "";
        if (!message && !form.mail) {
            message = "メールアドレスを入力してください。";
        }
        const pattern = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/;
        if (!message && !pattern.test(form.mail)) {
            message = "正しい形式のメールアドレスを入力してください。";
        }
        return message;
    }

    const validateText = () => {
        let message = "";
        if (!message && !form.text) {
            message = "お問い合わせ内容を入力してください。";
        }
        return message;
    }

    if (isSubmit) {
        return(
            <section className="page-section thanks">
                <h3 className="thanks-title">お問い合わせありがとうございます</h3>
                <div className="thanks-body">
                    <p>
                        このたびは、株式会社 メインへお問い合わせ頂き誠にありがとうございます。<br />
                        お問い合わせ頂いた内容については、確認の上、ご返信させていただきます。
                    </p>
                    <p>
                        なお、お急ぎの場合は電話でもご相談を受け付けております。<br />
                        <Tel underLine={true} /> までご遠慮なくご相談ください。
                    </p>
                </div>
            </section>
        )
    }

    return(
    <form onSubmit={(event) => handleSubmit(event)} className="form">
        {serverMessage ? <p className="form-error form-error-server">{serverMessage}</p> : <></>}
        <div className="form-item">
            <label htmlFor="name" className="form-label">氏名: </label>
            <input type="text" name="name" id="name" className="form-input" defaultValue={form.name} onChange={(event) => setForm({...form, name: event.target.value})} required />
            <p className="form-error">{error.name}</p>
        </div>
        <div className="form-item">
            <label htmlFor="strname" className="form-label">所属: </label>
            <input type="text" name="strname" id="strname" className="form-input" defaultValue={form.strname} onChange={(event) => setForm({...form, strname: event.target.value})} required />
            <p className="form-error">{error.strname}</p>
        </div>
        <div className="form-item">
            <label htmlFor="mail" className="form-label">メールアドレス: </label>
            <input type="email" name="mail" id="mail" className="form-input" defaultValue={form.mail} onChange={(event) => setForm({...form, mail: event.target.value})} required />
            <p className="form-error">{error.mail}</p>
        </div>
        <div className="form-item">
            <label htmlFor="text" className="form-label">お問い合わせ内容：</label>
            <textarea name="text" id="text" className="form-input form-input-textarea" defaultValue={form.text}  onChange={(event) => setForm({...form, text: event.target.value})} rows={10} required></textarea>
            <p className="form-error">{error.text}</p>
        </div>
        <div className="form-item">
            <input type="submit" className="button form-button form-button-primary" value="入力内容を送信する" />
        </div>
    </form>
    )
}

export default Contact