import * as React from "react";

import "./style.css";

/**
 * ページのファーストビューセクション
 */
const PageHeader = ({ title, children }) => {
    return(
        <section className="page-header">
            <div className="l-contents-padding page-header-contents">
                <h1 className="page-header-title">{title}</h1>
            </div>
            {children ? <div className="page-header-image-wrap">{children}</div> : <div className="l-border"></div>}
        </section>
    )
}

export default PageHeader;